<template>
    <div class="main mobile_container_top_margin">
        <div class="row">
            <div class="col-sm-12">
                <h1>Dev Tools</h1>
            </div>
            <div class="col-sm-12 margin_bottom">
                <ul class="nav nav-pills">
                    <li :class='(currentView === "email") ? "active" : ""'><a @click="setView('email')">Email</a></li>
                    <li :class='(currentView === "integratedReceivables") ? "active" : ""'><a @click="setView('integratedReceivables')">Wells Fargo: Integrated Receivables</a></li>
                </ul>
            </div>
            <DevEmail v-if="currentView === 'email'" />
            <IntegratedReceivables v-if="currentView === 'integratedReceivables'" />
        </div>
    </div>
</template>

<script>

import DevEmail from '@/components/tools/DevTools/Email.vue'
import IntegratedReceivables from "@/components/tools/DevTools/IntegratedReceivables";

export default {
    name: 'DevTools',
    components: {
        DevEmail,
        IntegratedReceivables
    },
    data() {
        return {
            currentView: 'email'
        }
    },
    methods: {
        setView: function(view) {
            this.currentView = view;
        }
    }
}

</script>

<style scoped>
    a {
        cursor: pointer;
    }
</style>